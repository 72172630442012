import { isEmpty } from 'lodash/fp';
import { useCallback } from 'react';

export const useValidationResolver = (validationSchema: any) => {
  return useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
};

export const handleErrors = (errors: { [key: string]: any }, field: string) => {
  if (isEmpty(errors) || isEmpty(errors[field]) || !errors[field] || !errors[field]?.message) {
    return undefined;
  }

  // errors is typed as DeepMap<FieldValues, FieldError> which includes undefined
  // @ts-ignore
  return [errors[field].message as string];
};
