import * as yup from 'yup';

export const passwordSchema = yup.object({
  password: yup
    .string()
    .ensure()
    .min(8, 'Password must be at least 8 characters.')
    .max(64, 'Password must be less than 64 characters.')
    .matches(/(?=.*[A-Z])/, {
      excludeEmptyString: true,
      message: 'At least one capital letter is required.',
    })
    .matches(/(?=.*\d)/, {
      excludeEmptyString: true,
      message: 'At least one number is required.',
    })
    .matches(/^[a-zA-Z0-9!@#$&()\\-`.+,/"]*$/, {
      excludeEmptyString: true,
      message: 'Only alphanumeric and special characters are allowed.',
    })
    .required('This field is required.'),
  confirm: yup
    .string()
    .ensure()
    .oneOf([yup.ref('password'), ''], 'Password does not match.')
    .required('This field is required.'),
});

export const loginSchema = yup.object({
  email: yup.string().ensure().email().required('This field is required.'),
  password: yup.string().ensure().required('This field is required.'),
  submission: yup.mixed().notRequired(),
});
