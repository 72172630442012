import * as React from 'react';
import { Box, BoxProps, PropsOf } from '@awning/components';
import NextLink from 'next/link';

interface OwnLinkProps extends Omit<BoxProps, 'as'>, PropsOf<typeof NextLink> {
  newTab?: null | boolean;
}

export const BaseLink = (props: OwnLinkProps) => {
  const { sx = {}, newTab = false, href, children, ...rest } = props;

  return newTab ? (
    <Box as="a" href={href as string} target="_blank" rel="noreferrer" sx={sx}>
      {children}
    </Box>
  ) : (
    <NextLink href={href} {...rest}>
      <Box as="a" href={href as string} sx={sx}>
        {children}
      </Box>
    </NextLink>
  );
};
