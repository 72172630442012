import * as React from 'react';
import axios from 'axios';
import Router from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import Image from 'next/image';
import {
  AwningLogo,
  Box,
  Button,
  Field,
  Flex,
  Grid,
  InputPassword,
  Text,
} from '@awning/components';
import { BaseLink } from '@/src/Link';
import styles from './fit.module.css';
import withSession from '@/src/auth/withSession';
import { AuthServerSidePropsContext } from 'types';
import { loginSchema } from '@/src/Accounts';
import { handleErrors, useValidationResolver } from '@/src/useValidationResolver';

export default function Login() {
  const [err, setErr] = React.useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm<{ email: string; password: string }>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: useValidationResolver(loginSchema),
  });

  async function onSubmit({ email, password }: any) {
    setErr(false);
    try {
      const {
        data: { redirectPath },
      } = await axios.post('/api/accounts/login', { email, password });

      Router.replace(redirectPath);
    } catch (error) {
      setErr(true);
    }
  }

  return (
    <Grid
      columns={[null, 2]}
      gap={0}
      sx={{
        height: {
          base: 'auto',
          sm: 'screenHeight',
        },
        overflow: 'hidden',
      }}
    >
      <Box
        as={AwningLogo}
        sx={{
          display: {
            base: 'block',
            sm: 'none',
          },
          my: 4,
          ml: 4,
        }}
      />
      <Box
        sx={{
          height: {
            base: '215px',
            sm: 'auto',
          },
          width: '100%',
          position: 'relative',
        }}
      >
        <Image
          src="/accounts/julian-gentilezza-ctUWE7BUEzE-unsplash.jpg"
          layout="fill"
          loading="eager"
          priority={true}
          className={styles.fit}
        />
      </Box>

      <Box
        sx={{
          boxShadow: '-3px 0px 20px 3px rgba(0, 0, 0, 0.25)',
          zIndex: 1,
          height: '100%',
          overflowY: 'scroll',
          pb: {
            base: 6,
            sm: 0,
          },
        }}
      >
        <Box
          sx={{
            px: {
              base: 8,
              sm: 0,
            },
            m: {
              base: 0,
              sm: '25% auto',
            },
            maxWidth: {
              lg: '400px',
            },
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              margin: '0 auto',
              width: 'fit-content',
            }}
          >
            <Box
              as={AwningLogo}
              sx={{
                display: {
                  base: 'none',
                  sm: 'block',
                },
              }}
            />

            <Text
              text="4xl"
              sx={{
                fontWeight: 'bold',
                color: 'black',
                mt: 8,
                mb: {
                  base: 1,
                  sm: 6,
                },
              }}
            >
              Welcome Back
            </Text>

            <Text text={['sm', 'xl']} sx={{ color: 'gray.600', mb: 8 }}>
              New here?
              <BaseLink
                href="https://awning.typeform.com/to/ARxVT9gV"
                sx={{
                  '&::after': {
                    content: '""',
                    width: '100%',
                    position: 'absolute',
                    left: 0,
                    bottom: '1px',
                    borderWidth: '0 0 1px',
                    borderStyle: 'solid',
                  },
                }}
              >
                Join our early access program
              </BaseLink>
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="email"
                render={props => (
                  <Field
                    label="Email"
                    type="email"
                    errors={handleErrors(errors, 'email')}
                    required={true}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={props => (
                  <Field
                    as={InputPassword}
                    sx={{ pb: 5 }}
                    label="Password"
                    type="password"
                    required={true}
                    errors={handleErrors(errors, 'password')}
                    {...props}
                  />
                )}
              />
              <Box sx={{ mb: 4 }}>
                <Button
                  type="submit"
                  size="lg"
                  sx={{
                    mt: {
                      base: 0,
                      sm: 5,
                    },
                  }}
                  isDisabled={!isValid || !isDirty || isSubmitting}
                >
                  Login
                </Button>
                {err && (
                  <Box text="xs" sx={{ color: 'red.600', mt: 2 }}>
                    Incorrect email or password. Please try again.
                  </Box>
                )}
              </Box>
            </form>

            <BaseLink href="/accounts/forgot" sx={{ text: 'sm' }}>
              Forgot Password?
            </BaseLink>
          </Flex>
        </Box>
      </Box>
    </Grid>
  );
}

export const getServerSideProps = withSession(async ({ req, res }: AuthServerSidePropsContext) => {
  const user = req.session.get('user');

  if (user !== undefined) {
    res.setHeader('location', '/feed');
    res.statusCode = 302;
    res.end();

    return { props: {} };
  }

  return { props: {} };
});
